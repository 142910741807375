import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "../store";
import { LANG_ABBREVIATIONS } from "../common/constants";

Vue.use(VueI18n);

const DEFAULT_LANG = store.getters.getCurrLanguage || LANG_ABBREVIATIONS.EN;

const locales = {
  en: require("./locales/en.json"),
  de: require("./locales/de.json"),
};

const numberFormats = {
  "de-DE": {
    currency: { style: "currency", currency: "EUR" },
    number: {},
    currencyMatPrice: {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
  },
};

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
  numberFormats,
});

export default i18n;
