import Vue from 'vue';
import VueRouter from 'vue-router';
import { USER_ROLES } from '../common/constants';

import { authenticate } from '../guards/authGuard';
import { validateResetPasswordToken } from '../guards/resetPasswordTokenValidationGuard';
import store from '../store';

//Adding layouts router.
const BlankLayout = () => import('@/layouts/BlankLayout');
const BlankFormLayout = () => import('@/layouts/BlankFormLayout');
const Layout = () => import('@/layouts/Layout');

// Static Pages.
const Error404 = () => import('@/views/Pages/Error/Error404');
// const Error500 = () => import("@/views/Pages/Error/Error500");
// const Maintainance = () => import("@/views/Pages/Maintainance");

// Auth routes.
const SignIn = () => import('@/views/Auth/SignIn');
const RecoverPassword = () => import('@/views/Auth/RecoverPassword');
const ResetPassword = () => import('@/views/Auth/ResetPassword');
// const LockScreen = () => import("@/views/Auth/LockScreen");

// Application
// const Dashboard = () => import("@/views/Dashboard/Dashboard");
const UserProfileEdit = () => import('@/views/Users/UserProfileEdit');
const PostUser = () => import('@/views/Users/PostUser');
const UserList = () => import('@/views/Users/UserList');

// const PostMaterial = () => import('@/views/Materials/PostMaterial');
// const MaterialList = () => import('@/views/Materials/MaterialList');

const PostCustomer = () => import('@/views/Customers/PostCustomer');
const CustomerList = () => import('@/views/Customers/CustomerList');

// const PostSupplier = () => import('@/views/Suppliers/PostSupplier');
// const SupplierList = () => import('@/views/Suppliers/SupplierList');

// const PostInventoryReceipt = () => import('@/views/InventoryReceipts/PostInventoryReceipt');
// const InventoryReceiptList = () => import('@/views/InventoryReceipts/InventoryReceiptList');

const PostCustomerInvoice = () => import('@/views/CustomerInvoices/PostCustomerInvoice');
const CustomerInvoiceList = () => import('@/views/CustomerInvoices/CustomerInvoiceList');

const PostOrder = () => import('@/views/Orders/PostOrder');
const OrderList = () => import('@/views/Orders/OrderList');
const OrderOverview = () => import('@/views/Orders/OrderOverview');
const OrderCalcList = () => import('@/views/Orders/OrderCalcList');

const HourlyWageList = () => import('@/views/HourlyWage/HourlyWageList');
const AlertsConfigurationList = () => import('@/views/AlertsConfiguration/AlertsConfigurationList');
const BillList = () => import('@/views/Bills/BillList');
// const InventoryList = () => import('@/views/Inventory/InventoryList');

Vue.use(VueRouter);

const appRoutes = () => [
  {
    path: '',
    name: 'order-overview',
    beforeEnter: (to, from, next) => {
      // Redirecting irrelevant roles to their home page.
      if (
        [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
          store.getters.getLoggedInUser.role
        )
      ) {
        next();
      } else {
        next({ name: 'list-bills' });
      }
    },
    meta: {
      name: 'Overview'

      // For this route (home), roles are being checked on per-route guard above.
      // rolesRequired: [
      //   USER_ROLES.ADMIN,
      //   USER_ROLES.OFFICE_WORKER,
      //   USER_ROLES.PROJECT_MANAGER,
      // ],
    },
    component: OrderOverview
  },

  // {
  //   path: "dashboard",
  //   name: "dashboard",
  //   meta: { name: "Dashboard", rolesRequired: [USER_ROLES.ADMIN] },
  //   component: Dashboard,
  // },

  {
    path: 'users',
    meta: { name: 'Users' },
    redirect: 'users/list',
    component: BlankLayout,
    children: [
      {
        path: 'list',
        name: 'list-users',
        meta: { name: 'Users List', rolesRequired: [USER_ROLES.ADMIN] },
        component: UserList
      },
      {
        path: 'add',
        name: 'add-user',
        meta: { name: 'user-add', rolesRequired: [USER_ROLES.ADMIN] },
        component: PostUser
      },
      {
        path: 'edit/:id',
        name: 'edit-user',
        meta: { name: 'edit-add', rolesRequired: [USER_ROLES.ADMIN] },
        component: PostUser
      }
    ]
  },

  // {
  //   path: 'materials',
  //   meta: {
  //     name: 'Materials'
  //   },
  //   redirect: 'materials/list',
  //   component: BlankLayout,
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'list-materials',
  //       meta: {
  //         name: 'Materials List',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: MaterialList
  //     },
  //     {
  //       path: 'add',
  //       name: 'add-material',
  //       meta: {
  //         name: 'Add Material',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: PostMaterial
  //     },
  //     {
  //       path: 'edit/:id',
  //       name: 'edit-material',
  //       meta: {
  //         name: 'Edit Material',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: PostMaterial
  //     }
  //   ]
  // },

  {
    path: 'customers',
    meta: {
      name: 'Customers'
    },
    redirect: 'customers/list',
    component: BlankLayout,
    children: [
      {
        path: 'list',
        name: 'list-customers',
        meta: {
          name: 'Customers List',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: CustomerList
      },
      {
        path: 'add',
        name: 'add-customer',
        meta: {
          name: 'Add Customer',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: PostCustomer
      },
      {
        path: 'edit/:id',
        name: 'edit-customer',
        meta: {
          name: 'Edit Customer',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: PostCustomer
      }
    ]
  },

  {
    path: 'orders',
    meta: { name: 'Orders' },
    redirect: 'orders/list',
    component: BlankLayout,

    children: [
      {
        path: 'list',
        name: 'list-orders',
        meta: {
          name: 'Orders List'
          // rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        beforeEnter: (to, from, next) => {
          // Redirecting irrelevant roles to their home page.
          if (
            [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
              store.getters.getLoggedInUser.role
            )
          ) {
            next();
          } else {
            next({ name: 'order-calc-list' });
          }
        },
        component: OrderList
      },
      {
        path: 'list',
        name: 'order-calc-list',
        meta: {
          name: 'Orders Calculator List',
          rolesRequired: [USER_ROLES.CALCULATOR]
        },
        component: OrderCalcList
      }
    ]
  },

  {
    path: 'orders',
    meta: { name: 'Order Form' },
    component: BlankFormLayout,
    children: [
      {
        path: 'add',
        name: 'add-order',
        meta: {
          name: 'Add Order',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: PostOrder
      },
      {
        path: 'edit/:id',
        name: 'edit-order',
        meta: {
          name: 'Edit Order',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: PostOrder
      }
    ]
  },

  // {
  //   path: 'suppliers',
  //   meta: {
  //     name: 'Suppliers'
  //   },
  //   redirect: 'suppliers/list',
  //   component: BlankLayout,
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'list-suppliers',
  //       meta: {
  //         name: 'Suppliers List',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: SupplierList
  //     },
  //     {
  //       path: 'add',
  //       name: 'add-supplier',
  //       meta: {
  //         name: 'Add Supplier',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: PostSupplier
  //     },
  //     {
  //       path: 'edit/:id',
  //       name: 'edit-supplier',
  //       meta: {
  //         name: 'Edit Supplier',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: PostSupplier
  //     }
  //   ]
  // },

  // {
  //   path: 'inventory-receipts',
  //   meta: {
  //     name: 'Inventory Receipts'
  //   },
  //   redirect: 'inventory-receipts/list',
  //   component: BlankLayout,
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'list-inventory-receipts',
  //       meta: {
  //         name: 'Inventory Receipts List',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: InventoryReceiptList
  //     },
  //     {
  //       path: 'add',
  //       name: 'add-inventory-receipt',
  //       meta: {
  //         name: 'Add Inventory Receipt',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: PostInventoryReceipt
  //     },
  //     {
  //       path: 'edit/:id',
  //       name: 'edit-inventory-receipt',
  //       meta: {
  //         name: 'Edit Inventory Receipt',
  //         rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
  //       },
  //       component: PostInventoryReceipt
  //     }
  //   ]
  // },

  {
    path: 'customer-invoices',
    meta: {
      name: 'Customer Invoices'
    },
    redirect: 'customer-invoices/list',
    component: BlankLayout,
    children: [
      {
        path: 'list',
        name: 'list-customer-invoices',
        meta: {
          name: 'Customer Invoices List',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: CustomerInvoiceList
      },
      {
        path: 'add',
        name: 'add-customer-invoice',
        meta: {
          name: 'Add Customer Invoice',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: PostCustomerInvoice
      },
      {
        path: 'edit/:id',
        name: 'edit-customer-invoice',
        meta: {
          name: 'Edit Customer Invoice',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: PostCustomerInvoice
      }
    ]
  },

  {
    path: 'hourly-wage',
    meta: {
      name: 'Hourly Wages'
    },
    redirect: 'hourly-wage/list',
    component: BlankLayout,
    children: [
      {
        path: 'list',
        name: 'list-hourly-wages',
        meta: {
          name: 'Hourly Wages List',
          rolesRequired: [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER]
        },
        component: HourlyWageList
      }
    ]
  },

  {
    path: 'email-alerts',
    meta: {
      name: 'Email Alerts'
    },
    component: BlankLayout,
    children: [
      {
        path: '',
        name: 'list-email-alerts',
        meta: {
          name: 'Alerts Configurations List',
          rolesRequired: [USER_ROLES.ADMIN]
        },
        component: AlertsConfigurationList
      }
    ]
  },

  {
    path: 'bills',
    meta: {
      name: 'Bills'
    },
    redirect: 'bills/list',
    component: BlankLayout,
    children: [
      {
        path: 'list',
        name: 'list-bills',
        meta: {
          name: 'Bills List',
          rolesRequired: [
            USER_ROLES.ADMIN,
            USER_ROLES.OFFICE_WORKER,
            USER_ROLES.PROJECT_MANAGER,
            USER_ROLES.PLANT_MANAGER,
            USER_ROLES.CALCULATOR
          ]
        },
        component: BillList
      }
    ]
  },

  // {
  //   path: 'inventory',
  //   meta: {
  //     name: 'Inventory'
  //   },
  //   redirect: 'inventory/list',
  //   component: BlankLayout,
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'list-inventory',
  //       meta: {
  //         name: 'Inventory List',
  //         rolesRequired: [
  //           USER_ROLES.ADMIN,
  //           USER_ROLES.OFFICE_WORKER,
  //           USER_ROLES.PROJECT_MANAGER,
  //           USER_ROLES.PLANT_MANAGER,
  //         ]
  //       },
  //       component: InventoryList
  //     }
  //   ]
  // },

  {
    path: 'profile',
    name: 'my-profile',
    meta: { name: 'My Profile' },
    component: UserProfileEdit
  }
];

const authRoutes = () => [
  {
    path: 'login',
    name: 'login',
    meta: { name: 'SignIn', excludeAuth: true },
    component: SignIn
  },
  {
    path: 'recover-password',
    name: 'recover-password',
    meta: { name: 'Recover Password', excludeAuth: true },
    component: RecoverPassword
  },
  {
    path: 'reset-password',
    name: 'reset-password',
    meta: { name: 'Reset Password', excludeAuth: true },
    component: ResetPassword,
    beforeEnter: validateResetPasswordToken
  }
  // {
  //   path: "lock-screen",
  //   name: "lock-screen",
  //   meta: { name: "Lock Screen", excludeAuth: true },
  //   component: LockScreen,
  // },
];

// const pageschildRoute = () => [
//   {
//     path: "error-500",
//     name: "error.500",
//     meta: { name: "Error 500" },
//     component: Error500,
//   },
//   {
//     path: "pages-maintainance",
//     name: "pages.maintenance",
//     meta: { name: "Maintaiance", excludeAuth: true },
//     component: Maintainance,
//   },
// ];

const routes = [
  {
    path: '/',
    name: '',
    component: Layout,
    children: appRoutes()
  },
  {
    path: '/auth',
    name: 'auth',
    component: BlankLayout,
    children: authRoutes()
  },

  // {
  //   path: "/pages",
  //   name: "pages",
  //   component: BlankLayout,
  //   children: pageschildRoute(),
  // },

  {
    path: '*',
    name: 'not-found',
    meta: { name: 'Page Not Found' },
    component: Error404
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

// Add in authentication guard to all routes.
router.beforeEach((to, from, next) => {
  authenticate(to, from, next);
});

export default router;
