import axios from "axios";

const state = {};

const getters = {};

const actions = {
  async getAlertsConfigurations(_obj, params) {
    let queryString = "";
    Object.keys(params).forEach((key, index) => {
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/alerts-configurations/${queryString}`);
    return res.data;
  },

  async getAlertsConfiguration(_obj, id) {
    const res = await axios.get(`api/alerts-configurations/${id}/`);
    return res.data;
  },

  async updateAlertsConfiguration(_obj, { id, data }) {
    return axios.patch(`api/alerts-configurations/${id}/`, data);
  },
};

const mutations = {};

export const alertsConfigurations = { state, getters, actions, mutations };
