import axios from 'axios';
import { cloneDeep } from 'lodash';

const state = {};

const getters = {};

const actions = {
  // Order APIs
  async getAllOrders(_obj, params) {
    let queryString = '';
    params = cloneDeep(params);
    Object.keys(params).forEach((key, index) => {
      if (key == 'search') {
        params[key] = encodeURIComponent(params[key]);
      }
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/orders/${queryString}`);
    return res.data;
  },

  async getOrder(_obj, id) {
    const res = await axios.get(`api/orders/${id}/`);
    return res.data;
  },

  async createOrder(_obj, data) {
    return axios.post(`api/orders/`, data);
  },

  async updateOrder(_obj, { id, data }) {
    return axios.patch(`api/orders/${id}/`, data);
  },

  async deleteOrder(_obj, id) {
    return axios.delete(`api/orders/${id}/`);
  },

  async validateOrderNumber(_obj, orderNum) {
    const res = await axios.get(`api/orders/validate-order-number/?order_number=${orderNum}`);
    return res.data;
  },

  async exportOrderResults(_obj, data) {
    const res = await axios.post(`api/orders/export-results/`, data);
    return res.data;
  },

  // Used materials APIs
  async getUsedMaterials(_obj, params) {
    let queryString = '';
    Object.keys(params).forEach((key, index) => {
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/orders/used-materials/${queryString}`);
    return res.data;
  },

  async getUsedMaterial(_obj, id) {
    const res = await axios.get(`api/orders/used-materials/${id}/`);
    return res.data;
  },

  async createUsedMaterial(_obj, data) {
    return axios.post(`api/orders/used-materials/`, data);
  },

  async updateUsedMaterial(_obj, { id, data }) {
    return axios.patch(`api/orders/used-materials/${id}/`, data);
  },

  async deleteUsedMaterial(_obj, id) {
    return axios.delete(`api/orders/used-materials/${id}/`);
  },

  async getOrdersCosts(_obj, { orderIds }) {
    return (await axios.post(`api/orders/calculate-costs/`, { order_ids: orderIds })).data;
  },

  // Drawing files APIs.
  async getDrawingFilesPresignedUrl(_obj, data) {
    return (await axios.post(`api/orders/drawing-files-upload-url/`, data)).data.data;
  },

  async getOrderDrawingFiles(_obj, params) {
    let queryString = '';
    Object.keys(params).forEach((key, index) => {
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/orders/drawing-files/${queryString}`);
    return res.data;
  },

  async addDrawingFilesToOrder(_obj, data) {
    return axios.post(`api/orders/add-drawing-files/`, data);
  },

  async deleteOrderDrawingFile(_obj, id) {
    return axios.delete(`api/orders/drawing-files/${id}/`);
  },
  async resendEmailtoCalculator(_obj, data) {
    return axios.post(`api/orders/drawing-files/resend-email/`, data);
  },

  async getAllCalculators(_obj, params) {
    let queryString = '';
    Object.keys(params).forEach((key, index) => {
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/users/get-calculators/${queryString}`);
    return res.data;
  },

  async getOrderBillandDrawingFileCount(_obj, params) {
    const res = await axios.post(`api/orders/bill-drawingfile-counts/`, params);
    return res.data;
  }
};

const mutations = {};

export const orders = { state, getters, actions, mutations };
