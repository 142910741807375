import axios from "axios";
import { last } from "lodash";

const state = {};

const getters = {};

const actions = {
  async downloadFile(_obj, { fileUrl, removeTimestamp = false, fileName = null }) {
    const fileResp = await axios.create().get(fileUrl, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([fileResp.data]));
    const link = document.createElement("a");
    link.href = url;

    // Parsing file name from url and optionally removing the timestamp from
    // the parsed file name.
    // i.e. 1601993129.266092_text.txt => text.txt
    fileName = fileName ? fileName : decodeURI(last(fileUrl.split("/")));
    if (removeTimestamp) fileName = fileName.replace(/^[0-9.]+_/, "");

    // Use the following if download doesn't work with certain browsers.
    // document.body.appendChild(link);

    // Downloading file through link attribute.
    link.setAttribute("download", fileName);
    link.click();
  },
};

const mutations = {};

export const fileDownload = { state, getters, actions, mutations };
