import store from "../store";
import { TOAST_OPTIONS } from "../common/constants";

export async function validateResetPasswordToken(to, from, next) {
    if (!store.getters.isLoggedIn) {
      try {
        await store.dispatch("validateResetPasswordToken", {
          token: to.query.token || "",
        });
        next();
      } catch (error) {
        store.dispatch("updateResetPasswordTokenInvalid", TOAST_OPTIONS.INVALID_TOKEN);
        next({ name: "login" });
      }
    } else next({ name: "order-overview" });
  }
