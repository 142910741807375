import axios from "axios";
import { cloneDeep } from "lodash";

const state = {};

const getters = {};

const actions = {
  async getAllCustomers(_obj, params) {
    let queryString = "";
    params = cloneDeep(params);
    Object.keys(params).forEach((key, index) => {
      if (key == "search") {
        params[key] = encodeURIComponent(params[key]);
      }
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/customers/${queryString}`);
    return res.data;
  },

  async getCustomer(_obj, id) {
    const res = await axios.get(`api/customers/${id}/`);
    return res.data;
  },

  async createCustomer(_obj, data) {
    return axios.post(`api/customers/`, data);
  },

  async updateCustomer(_obj, { id, data }) {
    return axios.patch(`api/customers/${id}/`, data);
  },

  async deleteCustomer(_obj, id) {
    return axios.delete(`api/customers/${id}/`);
  },

  async getAllCustomerOrders(_obj, id) {
    const res = await axios.get(`api/customers/${id}/customer-orders/`);
    return res.data;
  }
};

const mutations = {};

export const customers = { state, getters, actions, mutations };
