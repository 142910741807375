import axios from 'axios';
import { cloneDeep } from 'lodash';

const state = {};

const getters = {};

const actions = {
  async getAllMaterials(_obj, params) {
    let queryString = '';
    params = cloneDeep(params);
    Object.keys(params).forEach((key, index) => {
      if (key == 'search') {
        params[key] = encodeURIComponent(params[key]);
      }
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/materials/${queryString}`);
    return res.data;
  },

  async getMaterial(_obj, id) {
    const res = await axios.get(`api/materials/${id}/`);
    return res.data;
  },

  async createMaterial(_obj, data) {
    return axios.post(`api/materials/`, data);
  },

  async updateMaterial(_obj, { id, data }) {
    return axios.patch(`api/materials/${id}/`, data);
  },

  async deleteMaterial(_obj, id) {
    return axios.delete(`api/materials/${id}/`);
  },
  async getAllMaterialPrices(_obj, params) {
    const res = await axios.get(`api/materials/materials-price/`, { params });
    return res.data;
  },

  async getMaterialPrice(_obj, id) {
    const res = await axios.get(`api/materials/materials-price/${id}/`);
    return res.data;
  },

  async createMaterialPrice(_obj, data) {
    return axios.post(`api/materials/materials-price/`, data);
  },

  async updateMaterialPrice(_obj, { id, data }) {
    return axios.patch(`api/materials/materials-price/${id}/`, data);
  },

  async deleteMaterialPrice(_obj, id) {
    return axios.delete(`api/materials/materials-price/${id}/`);
  },
  async getCurrentMaterialPrice(_obj, params) {
    const res = await axios.post(`api/materials/materials-price/current-material-price/`, params);
    return res.data;
  }
};

const mutations = {};

export const materials = { state, getters, actions, mutations };
