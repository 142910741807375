import axios from "axios";
import { isEmpty } from "lodash";
import { LANG_ABBREVIATIONS } from "../../common/constants";

const state = {
  appName: "Werkstattmanager",
  // logo: require("../../assets/images/logo.png"),
  // darklogo: require("../../assets/images/white-logo-2.png"),
  dark: false,
  namespaced: true,
  currLanguage: LANG_ABBREVIATIONS.EN,
};

const getters = {
  appName: (state) => {
    return state.appName;
  },
  // logo: (state) => {
  //   return state.logo;
  // },
  // darklogo: (state) => {
  //   return state.darklogo;
  // },
  dark: (state) => {
    return state.dark;
  },
  getCurrLanguage: (state) => state.currLanguage,
};

const actions = {
  layoutModeAction(context, payload) {
    context.commit("layoutModeCommit", payload.dark);
  },
  async changeCurrLang({ commit, rootGetters }, lang) {
    if (!isEmpty(rootGetters.getLoggedInUser)) {
      await axios.post("api/users/update-language/", {
        language: lang,
      });
    }
    commit("changeCurrLang", lang);
  },
};

const mutations = {
  layoutModeCommit(state, payload) {
    state.dark = payload;
    // if (!payload) {
    //   state.logo = require("../../assets/images/logo.png");
    // } else {
    //   state.logo = require("../../assets/images/white-logo.png");
    // }
  },
  changeCurrLang(state, lang) {
    state.currLanguage = lang;
  },
};

export const config = {
  state,
  getters,
  actions,
  mutations,
};
