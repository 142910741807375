import axios from 'axios';
import { cloneDeep } from 'lodash';

const state = {};

const getters = {};

const actions = {
  async getInvoiceFilePresignedUrl(_obj, data) {
    return (await axios.post(`api/customer-invoices/customer-invoice-files-upload-url/`, data)).data.data;
  },

  // customerInvoice APIs
  async getAllCustomerInvoices(_obj, params) {
    let queryString = '';
    params = cloneDeep(params);
    Object.keys(params).forEach((key, index) => {
      if (key == 'search') {
        params[key] = encodeURIComponent(params[key]);
      }
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/customer-invoices/${queryString}`);

    return res.data;
  },

  async getCustomerInvoice(_obj, id) {
    const res = await axios.get(`api/customer-invoices/${id}/`);
    return res.data;
  },

  async createCustomerInvoice(_obj, data) {
    return axios.post(`api/customer-invoices/`, data);
  },

  async updateCustomerInvoice(_obj, { id, data }) {
    return axios.patch(`api/customer-invoices/${id}/`, data);
  },

  async deleteCustomerInvoice(_obj, id) {
    return axios.delete(`api/customer-invoices/${id}/`);
  },

  async exportCustomerInvoices(_obj, data) {
    const res = await axios.post(`api/customer-invoices/export-invoices/`, data);
    return res.data;
  }
};

const mutations = {};

export const customerInvoices = { state, getters, actions, mutations };
