import axios from "axios";

const state = {};

const getters = {};

const actions = {
  async getActivities(_obj, params) {
    const res = await axios.get(`api/activities/`, { params });
    return res.data;
  },

  async getActivity(_obj, id) {
    const res = await axios.get(`api/activities/${id}/`);
    return res.data;
  },
};

const mutations = {};

export const activities = { state, getters, actions, mutations };
