import axios from 'axios';
import { cloneDeep } from 'lodash';

const state = {};

const getters = {};

const actions = {
  async getInventory(_obj, params) {
    const qparams = cloneDeep(params);
    if (qparams.search) {
      qparams.search = encodeURIComponent(qparams.search);
    }

    const res = await axios.get(`api/inventory/`, { params: qparams });
    return res.data;
  },

  async updateInventory(_obj, { id, data }) {
    return axios.patch(`api/inventory/${id}/`, data);
  },

  async getRecipients(_obj, params = {}) {
    return await axios.get(`api/inventory/recipients/`, { params })
  },

  async setRecipients(_obj, data) {
    return await axios.post(`api/inventory/recipients/`, data);
  }
};

const mutations = {};

export const inventory = { state, getters, actions, mutations };
