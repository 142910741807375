export const DEFAULT_PAGE_SIZE = 10;

export const BILL_STATUSES = {
  PARSING: "parsing",
  SUCCESS: "success",
  ERROR_DOWNLOAD: "error_download",
  ERROR_PARSE: "error_parse",
  ERROR_ORDER_NOT_FOUND: "error_order_not_found",
};

export const USER_ROLES = {
  ADMIN: "admin",
  OFFICE_WORKER: "office_worker",
  PROJECT_MANAGER: "project_manager",
  PLANT_MANAGER: "plant_manager",
  CALCULATOR: "calculator",
};

export const DISCOUNT_UNITS = {
  PERCENTAGE: "percent",
  FIXED_VALUE: "fixed_value",
};

export const LANG_ABBREVIATIONS = {
  EN: "en",
  DE: "de",
};

export const PASSWORD_VALIDATIONS = {
  PASSWORDS_NOT_MATCH: "passwords_not_matched",
  PASSWORDS_MIN_LENGTH: "password_less_than_3_chars",
  PASSWORDS_ALL_NUMERIC: "password_all_numbers",
  INCORRECT_CURR_PASS: "incorrect_curr_pass",
};

export const ORDER_AMOUNT_TYPES = {
  COST: "cost",
  REVENUE: "revenue",
  MATERIAL: "material",
};

export const BILL_TYPES = {
  INVOICE: "invoice",
  AKKONTO: "akkonto",
};

export const ENVIRONMENTS = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const TOAST_OPTIONS = {
  INVALID_TOKEN: "token_invalid",
  RESET_SUCCESSFUL: "reset_successful",
};

export const LABOR_HRS_SUM_REGEX = /^((\d*,)?\d+[+])*(\d*,)?\d+$/;
export const PASSWORD_ALL_NUMERIC_REGEX = /^\d+$/;

export const CUSTOMER_INVOICE_TYPE_CHOICES = {
  WORKSHOP: 'workshop',
  INSTALLATION: 'installation'
};

export const MATERIAL_TYPES = {
  METAL_SHEET: "metal_sheet",
  SCREW: "screw"
};

export const DAY_NAMES = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday"
}
