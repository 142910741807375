import Axios from "axios";
import { get } from "lodash";

import store from "../store";
import router from "../router";
import { envConfig } from "./";

// Setting axios default config.

Axios.defaults.baseURL = `${envConfig.djangoServerProtocol}://${envConfig.djangoServerAddr}/`;

Axios.interceptors.request.use(function(request) {
  if (
    request.baseURL.includes(envConfig.djangoServerAddr) &&
    store.getters.isLoggedIn
  ) {
    const token = store.getters.getAuthToken;
    request.headers["Authorization"] = "Bearer " + token;
  }
  return request;
});

Axios.interceptors.response.use(
  // Refreshing app on build update.
  (response) => {
    const upcomingVer = response.headers["fe-version"] || "default";
    const currVer = localStorage.getItem("fe-version");
    if (!currVer) {
      localStorage.setItem("fe-version", upcomingVer);
    } else if (
      upcomingVer !== currVer &&
      response.config.method === "get" &&
      response.config.url !== "api/users/me/"
    ) {
      // For new build version, simply reload on any get request.
      localStorage.setItem("fe-version", upcomingVer);
      window.location.reload();
    }
    return Promise.resolve(response);
  },

  // Logging out user on 401.
  (error) => {
    if (
      error.response.status === 401 &&
      get(error, "response.config.baseURL", "").includes(
        envConfig.djangoServerAddr
      ) &&
      router.currentRoute.name !== "login"
    ) {
      store.dispatch("logout");
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);
