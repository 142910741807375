import Vue from 'vue';
import Vuex from 'vuex';
import {
  config,
  user,
  s3Upload,
  fileDownload,
  materials,
  customers,
  suppliers,
  customerInvoices,
  costCenters,
  orders,
  deliveryNotes,
  invoices,
  hourlyWages,
  billPapers,
  alertsConfigurations,
  comments,
  activities,
  notifications,
  inventory,
  inventoryReceipts,
} from './modules';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';
import { envConfig } from '../config';
import { ENVIRONMENTS } from '../common/constants';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config,
    user,
    fileDownload,
    s3Upload,
    materials,
    customers,
    suppliers,
    customerInvoices,
    costCenters,
    orders,
    deliveryNotes,
    invoices,
    hourlyWages,
    billPapers,
    alertsConfigurations,
    comments,
    activities,
    notifications,
    inventory,
    inventoryReceipts,
  },
  plugins: [
    // createPersistedState(),
    createPersistedState({
      storage: {
        getItem: key => Cookies.get(key),
        // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        setItem: (key, value) =>
          Cookies.set(key, value, {
            expires: 3,
            sameSite: 'Lax',
            ...(envConfig.env === ENVIRONMENTS.PRODUCTION && { secure: true }) // Make cookie secure for production.
          }),
        removeItem: key => Cookies.remove(key)
      }
    })
  ]
});
