import axios from "axios";

const state = {};

const getters = {};

const actions = {
  async getComments(_obj, params) {
    const res = await axios.get(`api/comments/`, { params });
    return res.data;
  },

  async getComment(_obj, id) {
    const res = await axios.get(`api/comments/${id}/`);
    return res.data;
  },

  async createComment(_obj, data) {
    return axios.post(`api/comments/`, data);
  },

  async updateComment(_obj, { id, data }) {
    return axios.patch(`api/comments/${id}/`, data);
  },

  async deleteComment(_obj, id) {
    return axios.delete(`api/comments/${id}/`);
  },

  async getCommentCount(_obj, data) {
    return axios.post(`api/comments/comments-count/`, data);
  }
};

const mutations = {};

export const comments = { state, getters, actions, mutations };
