import axios from "axios";
import { cloneDeep } from "lodash";

const state = {};

const getters = {};

const actions = {
  async getReceiptFilePresignedUrl(_obj, data) {
    return (await axios.post(`api/inventory-receipts/inventory-receipt-files-upload-url/`, data)).data
      .data;
  },

  // inventoryReceipt APIs
  async getAllInventoryReceipts(_obj, params) {
    let queryString = "";
    params = cloneDeep(params);
    Object.keys(params).forEach((key, index) => {
      if (key == "search") {
        params[key] = encodeURIComponent(params[key]);
      }
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/inventory-receipts/${queryString}`);
    return res.data;
  },

  async getInventoryReceipt(_obj, id) {
    const res = await axios.get(`api/inventory-receipts/${id}/`);
    return res.data;
  },

  async createInventoryReceipt(_obj, data) {
    return axios.post(`api/inventory-receipts/`, data);
  },

  async updateInventoryReceipt(_obj, { id, data }) {
    return axios.patch(`api/inventory-receipts/${id}/`, data);
  },

  async deleteInventoryReceipt(_obj, id) {
    return axios.delete(`api/inventory-receipts/${id}/`);
  },
};

const mutations = {};

export const inventoryReceipts = { state, getters, actions, mutations };
