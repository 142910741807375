import axios from "axios";

const state = {};

const getters = {};

const actions = {
  async uploadToPresignedUrl(_obj, { url, file, config = {} }) {
    return axios.create().put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
      ...config,
    });
  },
};

const mutations = {};

export const s3Upload = { state, getters, actions, mutations };
