import store from "../store";
import { get, includes } from "lodash";

// Role-based authentication route guard.
export function authenticate(to, from, next) {
  if (to.name === "login") {
    if (store.getters.isLoggedIn) {
      next({ name: "order-overview" });
    } else {
      next();
    }
  } else if (!to.meta.excludeAuth) {
    if (!store.getters.isLoggedIn) {
      next({ name: "login" });
    } else {
      if (
        !get(to.meta, "rolesRequired.length") ||
        includes(to.meta.rolesRequired, store.getters.getLoggedInUser.role)
      ) {
        next();
      } else {
        next({ name: "not-found" });
      }
    }
  }
  next();
}
