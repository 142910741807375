import axios from "axios";

const state = {};

const getters = {};

const actions = {
  async getInvoices(_obj, params) {
    const res = await axios.get(`api/invoices/`, { params });
    return res.data;
  },

  async getInvoice(_obj, id) {
    const res = await axios.get(`api/invoices/${id}/`);
    return res.data;
  },

  async getOrdersInvoices(_obj, params) {
    const res = await axios.post(`api/invoices/orders-invoices/`, params);
    return res.data;
  },

  async createInvoice(_obj, data) {
    return axios.post(`api/invoices/`, data);
  },

  async updateInvoice(_obj, { id, data }) {
    return axios.patch(`api/invoices/${id}/`, data);
  },

  async deleteInvoice(_obj, id) {
    return axios.delete(`api/invoices/${id}/`);
  },
};

const mutations = {};

export const invoices = { state, getters, actions, mutations };
