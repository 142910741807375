<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {},
};
</script>

<style lang="scss">
@import "./assets/scss/backend.scss";
@import "./assets/css/custom.css";
</style>
