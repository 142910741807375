import axios from 'axios';
import { cloneDeep } from 'lodash';

const state = {};

const getters = {};

const actions = {
  async getBillPapers(_obj, params) {
    const qparams = cloneDeep(params);
    if (qparams.search) {
      qparams.search = encodeURIComponent(qparams.search);
    }

    const res = await axios.get(`api/bill-of-material-papers/`, { params: qparams });
    return res.data;
  },

  async getBillPaper(_obj, id) {
    const res = await axios.get(`api/bill-of-material-papers/${id}/`);
    return res.data;
  },

  async createBillPaper(_obj, data) {
    return axios.post(`api/bill-of-material-papers/`, data);
  },

  async updateBillPaper(_obj, { id, data }) {
    return axios.patch(`api/bill-of-material-papers/${id}/`, data);
  },

  async archiveBillPaper(_obj, id) {
    return axios.delete(`api/bill-of-material-papers/${id}/archive/`);
  },

  async unarchiveBillPaper(_obj, id) {
    return axios.get(`api/bill-of-material-papers/${id}/unarchive/`);
  },

  async reparseBillPaper(_obj, id) {
    return axios.get(`api/bill-of-material-papers/${id}/reparse-material-bill-paper/`);
  },

  async updateBillDownloadedAt(_obj, id) {
    return axios.put(`api/bill-of-material-papers/${id}/update-downloaded-at/`, {});
  },

  async getBillPaperPresignedUrl(_obj, data) {
    return (await axios.post(`api/bill-of-material-papers/upload-url/`, data)).data.data;
  },

  async parseBillPaper(_obj, data) {
    return axios.post(`api/bill-of-material-papers/parse-material-bill-paper/`, data);
  }
};

const mutations = {};

export const billPapers = { state, getters, actions, mutations };
