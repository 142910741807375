import axios from "axios";
import { cloneDeep } from "lodash";

const state = {};

const getters = {};

const actions = {
  // Supplier APIs
  async getAllSuppliers(_obj, params) {
    let queryString = "";
    params = cloneDeep(params);
    Object.keys(params).forEach((key, index) => {
      if (key == "search") {
        params[key] = encodeURIComponent(params[key]);
      }
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/suppliers/${queryString}`);
    return res.data;
  },

  async getSupplier(_obj, id) {
    const res = await axios.get(`api/suppliers/${id}/`);
    return res.data;
  },

  async createSupplier(_obj, data) {
    return axios.post(`api/suppliers/`, data);
  },

  async updateSupplier(_obj, { id, data }) {
    return axios.patch(`api/suppliers/${id}/`, data);
  },

  async deleteSupplier(_obj, id) {
    return axios.delete(`api/suppliers/${id}/`);
  },
};

const mutations = {};

export const suppliers = { state, getters, actions, mutations };
