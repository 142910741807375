import axios from "axios";

const state = {};

const getters = {};

const actions = {
  async getHourlyWages(_obj, params) {
    let queryString = "";
    Object.keys(params).forEach((key, index) => {
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/hourly-wage/${queryString}`);
    return res.data;
  },

  async getHourlyWage(_obj, id) {
    const res = await axios.get(`api/hourly-wage/${id}/`);
    return res.data;
  },

  async createHourlyWage(_obj, data) {
    return axios.post(`api/hourly-wage/`, data);
  },

  async updateHourlyWage(_obj, { id, data }) {
    return axios.patch(`api/hourly-wage/${id}/`, data);
  },

  async deleteHourlyWage(_obj, id) {
    return axios.delete(`api/hourly-wage/${id}/`);
  },
};

const mutations = {};

export const hourlyWages = { state, getters, actions, mutations };
