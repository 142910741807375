import axios from "axios";
import { cloneDeep } from "lodash";

const state = {
  accessToken: "",
  refreshToken: "",
  user: {},
  resetPasswordTokenInvalid: null,
};

const getters = {
  getAuthToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getLoggedInUser: (state) => state.user,
  getResetPassTokenInvalid: (state) => state.resetPasswordTokenInvalid,
  isLoggedIn: (state) => {
    return state.accessToken !== "" && state.accessToken !== null;
  },
};

const actions = {
  async login({ commit }, data) {
    const loginResp = (
      await axios.post("api/token/", {
        email: data.email,
        password: data.password,
      })
    ).data;

    // Store in state.
    commit("login", {
      accessToken: loginResp.access,
      refreshToken: loginResp.refresh,
      user: loginResp.user,
    });

    return loginResp;
  },

  async logout({ commit }) {
    commit("logout");
  },

  // eslint-disable-next-line no-unused-vars
  async getMe(_obj) {
    const res = await axios.get(`api/users/me/`);
    return res.data;
  },

  async getAllUsers(_obj, params) {
    let queryString = "";
    params = cloneDeep(params);
    Object.keys(params).forEach((key, index) => {
      if (key === "search") {
        params[key] = encodeURIComponent(params[key]);
      }
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const res = await axios.get(`api/users/${queryString}`);
    return res.data;
  },

  async getUser(_obj, id) {
    const res = await axios.get(`api/users/${id}/`);
    return res.data;
  },

  async updateMyProfile({ commit }, data) {
    await axios.patch(`api/users/update-profile/`, data);

    commit("updateMyProfile", data);
  },

  async updateMyPassword(_obj, data) {
    await axios.patch(`api/users/update-password/`, data);
  },

  async createUser(_obj, data) {
    return axios.post(`api/users/`, data);
  },

  async updateUser(_obj, { id, data }) {
    return axios.patch(`api/users/${id}/`, data);
  },

  async deleteUser(_obj, id) {
    return axios.delete(`api/users/${id}/`);
  },

  async getManagedSites(_obj, params) {
    let queryString = "";
    Object.keys(params).forEach((key, index) => {
      if (index === 0) {
        queryString += `?${key}=${params[key]}`;
      } else {
        queryString += `&${key}=${params[key]}`;
      }
    });

    const response = await axios.get(`api/manager-sites/${queryString}`);
    return response.data;
  },

  async resetPassword(_obj, data) {
    return axios.post(`api/password-reset/`, data);
  },

  async resetPasswordConfirm(_obj, data) {
    return axios.post(`api/password-reset/confirm/`, data);
  },

  async validateResetPasswordToken(_obj, data) {
    return axios.post(`api/password-reset/validate_token/`, data);
  },

  updateResetPasswordTokenInvalid({ commit }, tokenInvalid) {
    commit("updatePassResetTokenInvalid", tokenInvalid);
  },
};

const mutations = {
  login: (state, res) => {
    state.accessToken = res.accessToken;
    state.refreshToken = res.refreshToken;
    state.user = res.user;
  },
  logout: (state) => {
    state.accessToken = "";
    state.refreshToken = "";
    state.user = {};
  },
  updateMyProfile: (state, updatedUser) => {
    state.user = { ...state.user, ...updatedUser };
  },
  updatePassResetTokenInvalid: (state, res) => {
    state.resetPasswordTokenInvalid = res;
  },
};

export const user = { state, getters, actions, mutations };
