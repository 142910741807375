import axios from "axios";

const state = {};

const getters = {};

const actions = {
  async getDeliveryNotes(_obj, params) {
    const res = await axios.get(`api/delivery-notes/`, { params });
    return res.data;
  },

  async getOrdersDeliveryNotes(_obj, params) {
    const res = await axios.post(`api/delivery-notes/orders-notes/`, params);
    return res.data;
  },

  async getDeliveryNote(_obj, id) {
    const res = await axios.get(`api/delivery-notes/${id}/`);
    return res.data;
  },

  async createDeliveryNote(_obj, data) {
    return axios.post(`api/delivery-notes/`, data);
  },

  async updateDeliveryNote(_obj, { id, data }) {
    return axios.patch(`api/delivery-notes/${id}/`, data);
  },

  async deleteDeliveryNote(_obj, id) {
    return axios.delete(`api/delivery-notes/${id}/`);
  },
};

const mutations = {};

export const deliveryNotes = { state, getters, actions, mutations };
